<template>
  <form class="v-components quiz-update-form" @submit.prevent="submit">
    <b-field grouped>
      <b-field
        label="Status"
        :type="{'is-danger': errors.has('status')}"
        :message="errors.first('status')">

        <b-select
          data-vv-as="Status"
          name='status'
          v-model="formData.status"
          v-validate="'boolean|required'"
          placeholder="Select">

          <option
            v-for="(status, key) in formValues.status"
            :value="status.code"
            :key="key">

            {{ status.name }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped>
      <b-field
        label="Question"
        :type="{'is-danger': errors.has('question')}"
        :message="errors.first('question')">

        <b-input
          type="text"
          v-model="formData.question"
          name="question"
          data-vv-as="Question"
          v-validate="'required'">
        </b-input>
      </b-field>
    </b-field>

    <div class="notification">
      <b-field
        label="Options">

        <b-tabs
          vertical
          type="is-boxed">

          <b-tab-item
            v-for="(option, key) in formData.options"
            :key="key"
            :label="`option ${key + 1}`">

            <b-field grouped>
              <b-field
                :type="{'is-danger': errors.has(`option-${key}-value`)}"
                :message="errors.first(`option-${key}-value`)"
                label="Value">

                <b-input
                  type="text"
                  v-model="option.value"
                  :name="`option-${key}-value`"
                  v-validate="'required'"
                  data-vv-as="Value">
                </b-input>
              </b-field>

              <b-field
                :type="{'is-danger': errors.has(`option-${key}-answer`)}"
                :message="errors.first(`option-${key}-answer`)"
                label="Answer">

                <b-checkbox
                  v-model="option.answer"
                  :name="`option-${key}-answer`"
                  v-validate="'boolean'"
                  data-vv-as="Answer">
                  Correct
                </b-checkbox>
              </b-field>
            </b-field>

            <div class="buttons">
              <b-button
                @click="formData.options.splice(key, 1)"
                type="is-danger"
                icon-left="delete">

                Delete
              </b-button>
            </div>
          </b-tab-item>
        </b-tabs>
      </b-field>

      <b-field
        v-if="optionAnswers.length"
        label="Correct options">

        <b-taglist>
          <b-tag
            type="is-dark"
            v-for="(option, key) in optionAnswers"
            :key="key">

            {{ option.value }}
          </b-tag>
        </b-taglist>
      </b-field>

      <b-field
        v-if="formData.options && formData.options.length"
        label="Position"
        class="sorting">

        <b-taglist>
          <draggable v-model="formData.options">
            <b-tag
              :style="{cursor: 'grab'}"
              v-for="(option, key) in formData.options.filter(o => o.value)"
              :key="key"

              closable
              @close="formData.options.splice(key, 1)"
              type="is-primary">

              {{ `${ option.value } | Option ${ key + 1 }` }}
            </b-tag>
          </draggable>
        </b-taglist>
      </b-field>

      <div class="buttons">
        <b-button
          @click="formData.options.push({ answer: false })"
          type="is-info"
          icon-left="plus">

          Add more option
        </b-button>
      </div>
    </div>

    <div class="notification">
      <div class="buttons">
        <b-button
          type="is-success"
          tag="input"
          native-type="submit"
          value="Update">
        </b-button>

        <b-button tag="router-link"
          :to="'/quizzes'"
          type="is-danger"
          icon-left="cancel"
          outlined>
          Cancel
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'QuizUpdateForm',
  props: {
    quiz: {
      type: Object,
      default: function() {
        return {}
      },
    },
    afterRedirect: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data: function() {
    return {
      connection_error: false,

      formData: {
        question: null,
        status: null,
        options: null,
      },
      formValues: {
        status: [
          {
            code: true,
            name: 'Enabled',
          },
          {
            code: false,
            name: 'Disabled',
          }
        ]
      },
    }
  },
  methods: {
    updateData: async function() {
      const thisCompo = this

      const loading = thisCompo.$buefy.loading.open()

      const formData = this.$deepCopy(this.formData)
      const newData = {
        id: formData.id,
        question: formData.question,
        status: formData.status,
      };

      if (formData.options) {
        newData.options = formData.options.map(opt => {
          return {
            value: opt.value,
            answer: opt.answer,
          }
        })
      }

      if (formData.status) {
        newData.status = formData.status
      }

      const updated = await this.$api.quizzesUpdate(newData).catch( (err) => {
        if (err.response) {
          thisCompo.$buefy.toast.open({
            message: err.response.data.message,
            type: 'is-danger',
            position: 'is-top'
          })
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Connection error',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }

        loading.close()
      })

      if (updated) {
        loading.close()

        if (this.afterRedirect) {
          this.$router.push(this.afterRedirect);
        }

        this.$buefy.toast.open({
          message: 'Successfully Updated',
          type: 'is-success',
          position: 'is-top'
        })
      }
    },
    submit: function() {
      const thisCompo = this

      this.$validator.validateAll().then(result => {
        if (result) {
          thisCompo.updateData()
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Form is not valid! Please check the fields.',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }
      })
    }
  },
  computed: {
    optionAnswers: function() {
      return this.formData.options ? this.formData.options.filter(option => {
        return option.answer ? true : false
      }) : []
    }
  },
  watch: {
    quiz: {
      immediate: true,
      handler: function(newVal) {
        this.formData.id = newVal._id
        this.formData.question = newVal.question
        this.formData.status = newVal.status
        this.formData.options = newVal.options
      },
    },
  },
}
</script>
