<template>
  <div class="page-quizzes-update">
    <div class="columns is-gapless is-vh">
      <div class="column is-2 has-background-dark">
        <Sidebar/>
      </div>

      <div class="column">
        <div v-if="loading == false" class="section">
          <div class="buttons">
            <b-button tag="router-link"
              :to="'/quizzes'"
              type="is-primary"
              icon-left="chevron-left">
              Back to Overview
            </b-button>
          </div>

          <h1 class="title">Update quiz</h1>
          <hr>

          <h3 class="subtitle"><strong>Id: </strong><em>{{ quiz._id }}</em></h3>

          <QuizUpdateForm :quiz="quiz" :afterRedirect="afterRedirect" />
        </div>

        <b-loading :is-full-page="true" :active.sync="loading"></b-loading>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import QuizUpdateForm from '@/components/forms/QuizUpdateForm.vue'

export default {
  name: 'QuizzesUpdate',
  components: {
    Sidebar,
    QuizUpdateForm,
  },
  data: function() {
    return {
      connection_error: false,
      loading: false,

      afterRedirect: {
        name: 'quizzes',
      },
      quiz: {},

      lastRequest: {},
    };
  },
  methods: {
    fetch: function(requestOptions={}) {
      const thisCompo = this;

      this.loading = true;
      this.connection_error = false;
      this.lastRequest = requestOptions

      this.$api.quizzesList(requestOptions).then((response) => {
        if (response.data.list.length) {
          thisCompo.quiz = response.data.list[0]
        }

        thisCompo.loading = false
      }).catch( (err) => {
        if (err.response) {
          thisCompo.$buefy.toast.open({
            message: 'Bad request',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Connection error',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }

        thisCompo.loading = false;
        thisCompo.connection_error = true;
      });
    },
  },
  mounted: function() {
    this.fetch({
      id: this.$route.params.id,
    })
  }
}
</script>
